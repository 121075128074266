<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <!-- ●TODO 登録と修正で共通の項目は保守性を考慮して同一ソースにまとめたい -->
            <v-card-title>仕入先登録</v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <!-- 仕入先コード -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.supplierCode"
                      label="仕入先コード"
                      :hint="`登録すると自動採番されます`"
                      persistent-hint
                      disabled
                      tabindex="1"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 仕入先名 -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.supplierName"
                      label="仕入先名"
                      :rules="[required, limit_length50]"
                      counter="50"
                      clearable
                      tabindex="2"
                      autofocus
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 仕入先名(カナ) -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.supplierKanaName"
                      label="仕入先名(カナ)"
                      :rules="[required, limit_length50]"
                      counter="50"
                      clearable
                      tabindex="3"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 手数料区分 -->
                  <v-col cols="12" sm="4">
                    <v-radio-group
                      v-model="currentParams.commissionSection"
                      label="手数料区分"
                      mandatory
                      dense
                      hide-details="false"
                    >
                      <v-radio
                        v-for="commission in commissionList"
                        :key="commission.id"
                        :label="commission.name"
                        :value="commission.id"
                        tabindex="4"
                        ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                  <!-- パナソニック区分 -->
                  <v-col cols="12" sm="4">
                    <v-radio-group
                      v-model="currentParams.panasonicSection"
                      label="パナソニック区分"
                      mandatory
                      dense
                      hide-details="false"
                    >
                      <v-radio
                        v-for="panasonic in panasonicList"
                        :key="panasonic.id"
                        :label="panasonic.name"
                        :value="panasonic.id"
                        tabindex="5"
                        ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                  <!-- トシン区分 -->
                  <v-col cols="12" sm="4">
                    <v-radio-group
                      v-model="currentParams.toshinSection"
                      label="トシン区分"
                      mandatory
                      dense
                      hide-details="false"
                    >
                      <v-radio
                        v-for="toshin in toshinList"
                        :key="toshin.id"
                        :label="toshin.name"
                        :value="toshin.id"
                        tabindex="6"
                        ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <!-- リベート区分 -->
                  <v-col cols="12" sm="4">
                    <v-radio-group
                      v-model="currentParams.rebateSection"
                      label="リベート区分"
                      mandatory
                      dense
                      hide-details="false"
                    >
                      <v-radio
                        v-for="rebate in rebateList"
                        :key="rebate.id"
                        :label="rebate.name"
                        :value="rebate.id"
                        tabindex="7"
                        ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                  <!-- 着日区分 -->
                  <v-col cols="12" sm="4">
                    <v-radio-group
                      v-model="currentParams.inDateSection"
                      label="着日区分"
                      mandatory
                      dense
                      hide-details="false"
                    >
                      <v-radio
                        v-for="inDate in inDateList"
                        :key="inDate.id"
                        :label="inDate.name"
                        :value="inDate.id"
                        tabindex="8"
                        ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                  <!-- 発注書区分 -->
                  <v-col cols="12" sm="4">
                    <v-radio-group
                      v-model="currentParams.purchaseOrderSection"
                      label="発注書区分"
                      mandatory
                      dense
                      hide-details="false"
                    >
                      <v-radio
                        v-for="purchaseOrder in purchaseOrderList"
                        :key="purchaseOrder.id"
                        :label="purchaseOrder.name"
                        :value="purchaseOrder.id"
                        tabindex="9"
                        ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <!-- CSS仕入先コード -->
                  <v-col cols="12" sm="12">
                    <v-autocomplete
                      v-model="currentParams.supplierCssCode"
                      label="CSS仕入先コード"
                      :items="supplierList"
                      item-text="comboItem"
                      item-value="code"
                      clearable
                      tabindex="10"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- 作成日付 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.createdDate"
                      label="作成日付"
                      :hint="`登録すると自動設定されます`"
                      persistent-hint
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 更新日付 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.updatedDate"
                      label="更新日付"
                      :hint="`登録すると自動設定されます`"
                      persistent-hint
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
              <!-- 登録 -->
              <v-btn
                color="secondary"
                v-on:click="onClickRegister"
                rounded
                class="my-2"
                tabindex="11"
              >
                <v-icon left> mdi-database-edit </v-icon>登録
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// graphqlのインポート
import { API, graphqlOperation } from "aws-amplify";
import { listM_SHIIRESAKI, getM_SHIIRESAKI } from "@/graphql/queries";
import { executeTransactSql, executeHanbaikanriSql } from "@/graphql/mutations";
import { CONFIRM_MESSAGE_UNSAVED_DATA, CONFIRM_MESSAGE_CLEAR_DATA, ALERT_MESSAGE_COMMON_ERROR, ALERT_COMPLETE_CRUD_DATA, ALERT_ALREADY_SAVED_DATA } from "@/assets/js/dialog_messages";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';

export default {
  name: "SupplierInsert",

  data: () => ({
    // 変数定義
    currentParams: {
      // 仕入先名（カナ）
      supplierKanaName: null,
      // 仕入先コード
      supplierCode: null,
      // 仕入先名
      supplierName: null,
      // CSS仕入先名（カナ）
      supplierCssKanaName: null,
      // CSS仕入先コード
      supplierCssCode: null,
      // 手数料区分
      commissionSection: 0,
      // パナソニック区分
      panasonicSection: 0,
      // トシン経由区分
      toshinSection: 0,
      // リベート区分
      rebateSection: 0,
      // 着日区分
      inDateSection: 0,
      // 発注書区分
      purchaseOrderSection: 3,
      // 作成日付
      createdDate: null,
      // 更新日付
      updatedDate: null,
    },
    // 未保存チェック用
    originalParams: {},

    // 検索用リスト（コード、名前）
    supplierList: [],

    // ログインユーザー情報
    loginUserInfo: null,

    // ラジオボタン初期設定
    commissionList: [
      { id: 0, name: "なし" },
      { id: 1, name: "NET計算あり" },
    ],
    panasonicList: [
      { id: 0, name: "その他" },
      { id: 1, name: "パナソニック" },
    ],
    toshinList: [
      { id: 0, name: "その他" },
      { id: 1, name: "トシン経由" },
    ],
    rebateList: [
      { id: 0, name: "なし" },
      { id: 1, name: "NET×0.94×1.0278（ﾊﾟﾅｿﾆｯｸ）" },
      { id: 2, name: "NET×0.97×1.0278（大光電機）" },
    ],
    inDateList: [
      { id: 0, name: "着日" },
      { id: 1, name: "出荷日" },
    ],
    purchaseOrderList: [
      { id: 3, name: "通常" },
      { id: 4, name: "トシンセンター" },
    ],
    // 入力規則
    // 入力必須の制約
    required: (value) => !!value || "必ず入力してください",
    // 文字数の制約 最大10文字
    limit_length5: (value) =>
      value == null || value.length <= 5 || "5文字以内で入力してください",
    // 文字数の制約 最大40文字
    limit_length50: (value) =>
      value == null || value.length <= 50 || "50文字以内で入力してください",
  }),
  computed: {},

  /**
   * created ライフサイクルフック
   */
  created: async function() {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0006"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

    // ローディング画面を表示
    this.$store.commit("setLoading", true);

    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // 初期化処理の実行
    if (!(await this.initialize())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * mounted ライフサイクルフック
   */
  mounted: async function() {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0007"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // バリデーションエラーをリセット
    this.$refs.form.resetValidation();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * beforeRouteLeave ライフサイクルフック
   */
  async beforeRouteLeave(to, from, next) {
    // メソッド名を定義
    const method_name="beforeRouteLeave"
    // オペレーションタイプを定義
    const operation_type="0008"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // 未保存チェックを実施し、未保存の場合はエラーを表示
    if (!this.isSavedData()) {
      if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
        // バリデーションチェックでエラーの際は登録しない
        if (!(await this.validateCheck())) {
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディング画面を表示
        this.$store.commit("setLoading", true);
        // 修正時の判定
        if (
          (await this.isRequestedUpdate()) ||
          typeof this.currentParams.supplierCode == "number"
        ) {
          // 修正：入力データで更新
          if (!(await this.updateTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            // 画面遷移しない
            return;
          }
        } else {
          // 新規登録：入力データを登録
          if (!(await this.insertTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            // 画面遷移しない
            return;
          }
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);
      }
    }
    next();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return this.loginUserInfo !== null;
    },
    /**
     * 初期化処理
     * ・コンボボックスのデータ取得
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の表示
      this.$store.commit("setLoading", true);
      // 検索画面からの遷移の場合、更新値を取得
      this.reqSupplierCode = this.$route.query.reqSupplierCode
      if (this.isRequestedUpdate()) {
        let updCondition = {
          BUMON_CD: this.loginUserInfo.BUMON_CD,
          SHIIRESAKI_CD: this.reqSupplierCode,
        };

        // ログ出力(SQL-start)
        addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
        // データ取得
        try {
          // AppSync→AuroraServerless(MySQL)でデータ取得
          let result = await API.graphql(
            graphqlOperation(getM_SHIIRESAKI, updCondition)
          );

          // 結果判定
          if (result.data.getM_SHIIRESAKI) {
            // データを取得できた場合
            // 取得結果をリストに追加
            let resultData = result.data.getM_SHIIRESAKI;
            this.currentParams = {
              supplierCode: resultData.SHIIRESAKI_CD,
              supplierKanaName: resultData.SHIIRESAKI_KANA_NAME,
              supplierName: resultData.SHIIRESAKI_NAME,
              supplierCssCode: resultData.CSS_SHIIRESAKI_CD,
              commissionSection: resultData.TESURYO_KBN,
              panasonicSection: resultData.PANASONIC_KBN,
              toshinSection: resultData.TOSHIN_KEIYU_KBN,
              rebateSection: resultData.SHIIRESAKI_REBATE_KBN,
              inDateSection: resultData.CHAKUBI_KBN,
              purchaseOrderSection: resultData.HACCHUSHO_KBN,
              createdDate: resultData.CREATE_DATE,
              updatedDate: resultData.UPDATE_DATE,
            };
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "getM_SHIIRESAKI", where_option:updCondition}, this.$route.name, method_name, TYPE["0003"],);
          } else {
            if (result.errors) {
              // データを取得できなかった場合
              // @TODO データ取得無しの場合の処理を以下に記載。
              // ログ出力(SQL実行でエラー)
              addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_SHIIRESAKI", where_option:updCondition}, this.$route.name, method_name, TYPE["0003"],);
              return false;
            } else {
              // データ0件時
              // ログ出力(SQL-end_0)
              addLog('INFO', {message: MSG.INFO["1005"], query: "getM_SHIIRESAKI", where_option:updCondition}, this.$route.name, method_name, TYPE["0003"],);
            }
          }
        } catch (error) {
          // Exception発生の場合
          // @TODO 異常系の処理を以下に記載。
          console.log(JSON.stringify(error));
          // ログ出力(exception)
          addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_SHIIRESAKI", where_option:updCondition}, this.$route.name, method_name, TYPE["0003"], error);
          return false;
        }
      }
      let sqlList = [
        `SELECT SI_KANA, SI_TORCD, SI_TORNM1 FROM SHIIRE` +
          ` WHERE SI_EBUCD = ${this.loginUserInfo.BUMON_CD}` +
          ` AND SI_EDELKB <> 1` +
          " ORDER BY SI_KANA",
      ];

      let resultData = await this.executeHanbaikanriSql(sqlList);
      if (!resultData) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      for (const data of resultData[0]) {
        this.supplierList.push({
          comboItem: data.SI_TORCD + "：" + data.SI_KANA,
          code: data.SI_TORCD,
          name: data.SI_TORNM1
        });
      }
      // 入力前のデータを保存
      this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 登録ボタン押下処理
     */
    async onClickRegister() {
      // メソッド名を定義
      const method_name="onClickRegister"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // バリデーションチェックでエラーの際は登録しない
      if (!(await this.validateCheck())) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 保存済みデータの場合、ダイアログを表示
      if (this.isSavedData()) {
        alert(ALERT_ALREADY_SAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の表示
      this.$store.commit("setLoading", true);

      // 修正ボタン押下もしくは連続登録しなかった場合
      if (
        (await this.isRequestedUpdate()) ||
        typeof this.currentParams.supplierCode == "number"
      ) {
        // 修正：入力データで更新
        if (!(await this.updateTable())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // エラーダイアログを表示
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);
      } else {
        // 新規登録：入力データを登録
        if (!(await this.insertTable())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // エラーダイアログを表示
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);

        if (confirm(CONFIRM_MESSAGE_CLEAR_DATA)) {
          // 連続登録の場合
          this.$store.commit("setLoading", true);
          // 画面上のデータを初期化
          this.currentParams = {
            supplierKanaName: "",
            supplierName: "",
            supplierCode: "",
            supplierCssKanaName: "",
            supplierCssCode: null,
            commissionSection: 0,
            panasonicSection: 0,
            toshinSection: 0,
            rebateSection: 0,
            inDateSection: 0,
            purchaseOrderSection: 3,
            createdDate: null,
            createdUser: null,
            updatedDate: null,
            updatedUser: null,
          };
          // 未保存チェック用配列を更新
          this.saveOriginalParams();

          // バリデーションエラーをリセット
          this.$refs.form.resetValidation();
        }
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 新規登録処理
     */
    async insertTable() {
      // メソッド名を定義
      const method_name="insertTable"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // CSS仕入先コード未選択の場合、nullを格納
      // コード最大値の取得
      let currentMaxCode;
      // 検索条件
      let condition = {
        where_options:
          "AND BUMON_CD = " +
          this.loginUserInfo.BUMON_CD +
          " ORDER BY SHIIRESAKI_CD DESC LIMIT 1",
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_SHIIRESAKI, condition)
        );

        // 結果判定
        if (result.data.listM_SHIIRESAKI) {
          // データを取得できた場合
          // DBから取得した値を設定
          let resultData = result.data.listM_SHIIRESAKI;
          currentMaxCode = resultData ? resultData[0].SHIIRESAKI_CD : 0;
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // 業務ログ出力
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // 業務ログ出力
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // 登録データ設定
      let sqlList = [
        `INSERT INTO M_SHIIRESAKI VALUES (` +
          `${this.loginUserInfo.BUMON_CD},` +              // BUMON_CD
          ` ${currentMaxCode + 1},` +                      // SHIIRESAKI_CD
          ` '${this.currentParams.supplierName}',` +       // SHIIRESAKI_NAME
          ` '${this.currentParams.supplierKanaName}',` +   // SHIIRESAKI_KANA_NAME
          ` ${this.currentParams.supplierCssCode},` +      // CSS_SHIIRESAKI_CD
          ` ${this.currentParams.panasonicSection},` +     // PANASONIC_KBN
          ` ${this.currentParams.commissionSection},` +    // TESURYO_KBN
          ` ${this.currentParams.toshinSection},` +        // TOSHIN_KEIYU_KBN
          ` ${this.currentParams.rebateSection},` +        // SHIIRESAKI_REBATE_KBN
          ` ${this.currentParams.inDateSection},` +        // CHAKUBI_KBN
          ` ${this.currentParams.purchaseOrderSection},` + // HACCHUSHO_KBN
          ` CURDATE(),` +                                  // CREATE_DATE
          ` '${this.loginUserInfo.LOGIN_USER_CD}',` +      // CREATE_USER_NAME
          ` CURDATE(),` +                                  // UPDATE_DATE
          ` '${this.loginUserInfo.LOGIN_USER_CD}')`,       // UPDATE_USER_NAME
        `SELECT * FROM M_SHIIRESAKI` +
          ` WHERE BUMON_CD = ${this.loginUserInfo.BUMON_CD}` +
          ` AND SHIIRESAKI_CD = ${currentMaxCode + 1}`
      ];
      // トランザクション処理の実行
      let resultData = await this.executeTransactSql(sqlList);
      if (resultData && (resultData[1][0] == null || resultData[1][0] == void 0)) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // 登録後のデータで画面を更新
      let data = resultData[1][0];
      this.currentParams = {
        supplierCode: data.SHIIRESAKI_CD,
        supplierName: data.SHIIRESAKI_NAME,
        supplierKanaName: data.SHIIRESAKI_KANA_NAME,
        supplierCssCode: data.CSS_SHIIRESAKI_CD,
        panasonicSection: data.PANASONIC_KBN,
        commissionSection: data.TESURYO_KBN,
        toshinSection: data.TOSHIN_KEIYU_KBN,
        rebateSection: data.SHIIRESAKI_REBATE_KBN,
        inDateSection: data.CHAKUBI_KBN,
        purchaseOrderSection: data.HACCHUSHO_KBN,
        createdDate: data.CREATE_DATE,
        createdUser: data.CREATE_USER_NAME,
        updatedDate: data.UPDATE_DATE,
        updatedUser: data.UPDATE_USER_NAME,
      };

      // 未保存チェック用配列を更新
      this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 完了ダイアログを表示
      return true;
    },
    /**
     * 修正処理
     */
    async updateTable() {
      // メソッド名を定義
      const method_name="updateTable"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 更新用データ生成
      let sqlList = [
        `UPDATE M_SHIIRESAKI SET` +
          ` BUMON_CD = ${this.loginUserInfo.BUMON_CD},` +
          ` SHIIRESAKI_CD = ${this.currentParams.supplierCode},` +
          ` SHIIRESAKI_NAME = '${this.currentParams.supplierName}',` +
          ` SHIIRESAKI_KANA_NAME = '${this.currentParams.supplierKanaName}',` +
          ` CSS_SHIIRESAKI_CD = ${this.currentParams.supplierCssCode},` +
          ` PANASONIC_KBN = ${this.currentParams.panasonicSection},` +
          ` TESURYO_KBN = ${this.currentParams.commissionSection},` +
          ` TOSHIN_KEIYU_KBN = ${this.currentParams.toshinSection},` +
          ` SHIIRESAKI_REBATE_KBN = ${this.currentParams.rebateSection},` +
          ` CHAKUBI_KBN = ${this.currentParams.inDateSection},` +
          ` HACCHUSHO_KBN = ${this.currentParams.purchaseOrderSection},` +
          ` UPDATE_DATE = CURDATE(),` +
          ` UPDATE_USER_NAME = '${this.loginUserInfo.LOGIN_USER_CD}'` +
          ` WHERE BUMON_CD = ${this.loginUserInfo.BUMON_CD}` +
          ` AND SHIIRESAKI_CD = ${this.currentParams.supplierCode}`,
        `SELECT * FROM M_SHIIRESAKI` +
          ` WHERE BUMON_CD = ${this.loginUserInfo.BUMON_CD}` +
          ` AND SHIIRESAKI_CD = ${this.currentParams.supplierCode}`
      ];
      // トランザクション処理の実行
      let resultData = await this.executeTransactSql(sqlList);
      if (resultData[0][0] == null || resultData[0][0] == void 0) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // 登録後のデータで画面を更新
      let data = resultData[0][0];
      this.currentParams = {
        supplierCode: data.SHIIRESAKI_CD,
        supplierName: data.SHIIRESAKI_NAME,
        supplierKanaName: data.SHIIRESAKI_KANA_NAME,
        supplierCssCode: data.CSS_SHIIRESAKI_CD,
        panasonicSection: data.PANASONIC_KBN,
        commissionSection: data.TESURYO_KBN,
        toshinSection: data.TOSHIN_KEIYU_KBN,
        rebateSection: data.SHIIRESAKI_REBATE_KBN,
        inDateSection: data.CHAKUBI_KBN,
        purchaseOrderSection: data.HACCHUSHO_KBN,
        createdDate: data.CREATE_DATE,
        createdUser: data.CREATE_USER_NAME,
        updatedDate: data.UPDATE_DATE,
        updatedUser: data.UPDATE_USER_NAME,
      };

      // 未保存チェック用配列を更新
      this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 完了ダイアログを表示
      return true;
    },
    /**
     * 未保存チェック用比較データの更新
     */
    saveOriginalParams() {
      // メソッド名を定義
      const method_name="saveOriginalParams"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 保存時の入力データを未保存チェック時の比較データとして更新
      this.originalParams = JSON.parse(JSON.stringify(this.currentParams));
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 未保存チェック
     */
    isSavedData() {
      // メソッド名を定義
      const method_name="isSavedData"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return (
        JSON.stringify(this.currentParams) ==
        JSON.stringify(this.originalParams)
      );
    },
    /**
     * 既存データの更新チェック
     */
    isRequestedUpdate() {
      // メソッド名を定義
      const method_name="isRequestedUpdate"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return this.reqSupplierCode !== void 0;
    },
    /**
     * バリデーションチェック
     */
    validateCheck() {
      // メソッド名を定義
      const method_name="validateCheck"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // バリデーションチェックでエラーの際は登録しない
      const form = this.$refs.form;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 販売管理システム用関数の実行処理
     */
    async executeHanbaikanriSql(sqlList) {
      // メソッド名を定義
      const method_name="executeHanbaikanriSql"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      let condition = { SQLs: sqlList };
      console.log(
        "executeHanbaikanriSql：condition = " + JSON.stringify(condition)
      );
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeHanbaikanriSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(
            result.data.executeHanbaikanriSql.body
          );

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeHanbaikanriSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            console.log("executeHanbaikanriSql：error");
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return null;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, { depth: null });
              }
            }
            console.log("executeHanbaikanriSql：SUCCESS ");
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return responseBody.data;
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("executeHanbaikanriSql : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return null;
        }
      } catch (error) {
        console.log(
          "executeHanbaikanriSql : 異常終了 = " + JSON.stringify(error)
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return null;
      }
    },
    /**
     * トランザクション用関数の実行処理
     */
    async executeTransactSql(sqlList) {
      // メソッド名を定義
      const method_name="executeTransactSql"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let condition = { SQLs: sqlList };
      console.log(
        "executeTransactSql：condition = " + JSON.stringify(condition)
      );
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(
            result.data.executeTransactSql.body
          );

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            console.log("executeTransactSql：error");
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return null;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, { depth: null });
              }
            }
            console.log("executeTransactSql：SUCCESS ");
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return responseBody.data;
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("executeTransactSql : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でステータスエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return null;
        }
      } catch (error) {
        console.log(
          "executeTransactSql : 異常終了 = " + JSON.stringify(error)
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return null;
      }
    },
  },
};
</script>
